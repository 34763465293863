import { MDBCol, MDBRow } from 'mdbreact'
import ContactForm from './contact/contactform'

import React from 'react'
import Stars from './ratings/centerstars'
import styled from 'styled-components'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import goodFaithEstimateImage from './images/GoodFaithEstimateImage.png'

const Img = styled.img`
  max-width: 3.5em;
`

const TopCenter = (props) => {
  const post = props.post
  const awards = post.relationships.field_awards_and_affiliations
  const allPracticemaxRatings = props.allPracticemaxRatings

  //  Separate the two kind of Awards
  let cmsAwards = []
  let othAwards = []
  if (awards != null) {
    awards.map((award) => {
      if (award.name.includes('CMS ')) {
        //uncomment this to include CMS awards
        // cmsAwards.push(award)
      } else {
        othAwards.push(award)
      }
    })
  }

  let numCmsAwards
  cmsAwards.length < 1 || cmsAwards == undefined
    ? (numCmsAwards = 0)
    : (numCmsAwards = getRowsArray(cmsAwards.length))
  let numOthAwards
  othAwards.length < 1 || othAwards == undefined
    ? (numOthAwards = 0)
    : (numOthAwards = getRowsArray(othAwards.length))

  let displayValue = `d-block`
  let displayTitle = `d-block`
  let titleSpace = `6`
  if (numCmsAwards === 0 && numOthAwards === 0) {
    displayTitle = `d-none`
  }
  if (
    numCmsAwards === 0 &&
    numOthAwards === 0 &&
    false && //always has the visitation status now //!post.relationships.field_visitation_status &&
    true //removed the testing frequency status //!post.relationships.field_testing_frequency_status
  ) {
    displayValue = `d-none`
    titleSpace = `12`
  }
  let logosSpaceLg = parseInt(titleSpace) - 1

  function getRowsArray(numRows) {
    let colValue
    colValue = `3`
    if (numRows === 1) {
      colValue = `12`
    }
    if (numRows === 2) {
      colValue = `6`
    }
    if (numRows % 3 === 0) {
      colValue = `4`
    }
    if (numRows % 4 === 0) {
      colValue = `3`
    }

    return colValue
  }

  return (
    <>
      <MDBRow className="my-0 mx-auto">
        <MDBCol
          xs="12"
          sm="12"
          md={titleSpace}
          lg={titleSpace}
          className="col-12 p-0 h-100 my-1"
        >
          <h1 className="pagetitleCenters">{post.title}</h1>
          <Stars
            center={props.location.pathname.toLowerCase()}
            starsdata={props.starsdata}
            allPracticemaxRatings={allPracticemaxRatings}
            extValues={true}
          />
          <p>{props.address}</p>
          <p>
            Main Phone Number: {props.mainphone}
            {props.mainfax == '' ? (
              ''
            ) : (
              <>
                <br />
                Main Fax Number: {props.mainfax}
              </>
            )}
            {props.admissionsphone == '' ? (
              ''
            ) : (
              <>
                <br />
                Admissions Phone Number: {props.admissionsphone}
              </>
            )}
            {props.admissionsefax == '' ? (
              ''
            ) : (
              <>
                <br />
                Admissions e-Fax: {props.admissionsefax}
              </>
            )}
          </p>
          <MDBRow>
            {cmsAwards.map((award, aKey) => {
              let logoAward = ``
              {
                award.relationships.field_logo_image
                  ? (logoAward =
                      award.relationships.field_logo_image.localFile
                        .childImageSharp.resize.src)
                  : ``
              }
              return (
                <MDBCol key={aKey} className={`my-1 `}>
                  <a href={award.field_service_page_link} target="_blank">
                    <Img src={logoAward} alt={award.name} title={award.name} />
                  </a>
                </MDBCol>
              )
            })}
          </MDBRow>
          <MDBRow>
            {othAwards.map((award, aKey) => {
              let logoAward = ``
              {
                award.relationships.field_logo_image
                  ? (logoAward =
                      award.relationships.field_logo_image.localFile
                        .childImageSharp.resize.src)
                  : ``
              }
              return (
                <MDBCol key={aKey} className={` my-1 `}>
                  <a href={award.field_service_page_link} target="_blank">
                    <Img src={logoAward} alt={award.name} title={award.name} />
                  </a>
                </MDBCol>
              )
            })}
          </MDBRow>
        </MDBCol>
        <MDBCol
          xs="12"
          sm="12"
          md="4"
          lg="5"
          className={`col-12 mx-auto p-2 ${displayValue}`}
        >
          <MDBRow center>
            <MDBCol>
              <GoogleReCaptchaProvider reCaptchaKey="6Lcm5ooqAAAAAGxCbMiqcSHiqFeMvv1FjKCuj8sz">
                <ContactForm
                  centeremail={props.centeremail}
                  centeraddress={props.address}
                  admissionsphone={props.admissionsphone}
                />
              </GoogleReCaptchaProvider>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default TopCenter
