import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import useDimensions from 'react-cool-dimensions'

const Input = ({ label, register, required, placeholder }) => (
  <>
    {/* <label>{label}</label> */}
    <input
      style={{
        width: '100%',
        marginTop: '.2rem',
        display: 'block',
        padding: '5px',
        border: '2px solid #555555',
        borderRadius: '5px',
        WebkitBorderRadius: '5px',
      }}
      placeholder={placeholder}
      {...register(label, { required })}
    />
  </>
)

const ContactMessage = ({ label, register, required }) => (
  <>
    <textarea
      placeholder="Enter your message."
      maxLength={300}
      style={{
        width: '100%',
        marginTop: '.2rem',
        display: 'block',
        padding: '5px',
        border: '2px solid #555555',
        borderRadius: '5px',
        WebkitBorderRadius: '5px',
      }}
      {...register(label, { required })}
    ></textarea>
  </>
)

const ContactAbout = ({ register, selectedValue, onRadioChange }) => {
  return (
    <>
      <p style={{ color: '#e61d26' }}>Please contact me about: </p>
      <input
        style={{ marginLeft: '0.2rem' }}
        {...register('About', { required: true })}
        type="radio"
        value="care"
        checked={selectedValue === 'care'}
        onChange={() => onRadioChange('care')}
      />
      <label style={{ marginLeft: '0.2rem' }}>Care</label>
      <input
        style={{ marginLeft: '0.2rem' }}
        {...register('About', { required: true })}
        type="radio"
        value="job"
        checked={selectedValue === 'job'}
        onChange={() => onRadioChange('job')}
      />
      <label style={{ marginLeft: '0.2rem' }}>Job</label>
      <input
        style={{ marginLeft: '0.2rem' }}
        {...register('About', { required: true })}
        type="radio"
        value="other"
        checked={selectedValue === 'other'}
        onChange={() => onRadioChange('other')}
      />
      <label style={{ marginLeft: '0.2rem' }}>Other</label>
    </>
  )
}

const ContactForm = (props) => {
  const doneRef = useRef(null)
  const errorRef = useRef(null)
  const nameFieldRef = useRef(null)
  const [resultWidth, setResultWidth] = useState(0)
  const [resultHeight, setResultHeight] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('care')

  const handleRadioChange = (value) => {
    setSelectedValue(value)
  }

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve()
      observe()

      if (formState == 'ready') {
        setResultWidth(width)
        setResultHeight(height)
        //console.log(`Width: ${resultWidth}, height=${resultHeight}`)
      } else {
        // console.log('resized, but not ready')
        // console.log(`Width: ${resultWidth}, height=${resultHeight}`)
      }
    },
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      //console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('www_contact_form')
    return token
  }, [executeRecaptcha])

  useEffect(async () => {
    const t = await handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  const [formState, setFormState] = useState('ready')

  let centeremail = props.centeremail
    .toLowerCase()
    .replace('sga_admissions', '')
    .replace('@genesishcc.com', '')

  let centeraddress = props.centeraddress

  const centerlocationinfo = `${centeremail}_${centeraddress}`

  let admissionsphone = props.admissionsphone

  const { register, handleSubmit } = useForm()

  const onInvalidSubmit = async (data) => {}

  const onSubmit = async (data) => {
    setIsLoading(true)
    const token = await executeRecaptcha('wwwcontactform')

    const full_message = `Contact About: "${data.About}"\r\nContact Message:\r\n${data.Message}\r\n\r\n==========\r\nRouting Info (Office Use):${centerlocationinfo}`

    const formData = new FormData()
    formData.append('token', token)
    formData.append('centeremail', centeremail)
    formData.append('name', data.Name)
    formData.append('contact', data.ContactInfo)
    formData.append('message', full_message)
    try {
      const x = await fetch(`https://www.getyourcreds.com/recaptcha/rc`, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      })

      setFormState('done')
    } catch (e) {
      console.log(e)
      setFormState('error')
    }
  }

  return (
    <>
      <ContainerDivS>
        {formState == 'done' ? (
          <FormDoneS ref={doneRef}>
            <div>
              <div
                style={{
                  width: resultWidth,
                  height: resultHeight,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3 style={{}}>Thank you</h3>
                <div style={{}}>
                  <p>We will be in touch.</p>
                </div>
              </div>
            </div>
          </FormDoneS>
        ) : null}

        {formState == 'error' ? (
          <FormErrorS ref={errorRef}>
            <div>
              <div
                style={{
                  width: resultWidth,
                  height: resultHeight,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3 style={{}}>Please Call</h3>
                <div style={{}}>
                  <p>
                    Your message could not be delivered. Please call us at{' '}
                    {admissionsphone}
                  </p>
                </div>
              </div>
            </div>
          </FormErrorS>
        ) : null}

        {formState == 'ready' ? (
          <FormS ref={observe} onSubmit={handleSubmit(onSubmit)} style={{}}>
            <ContactAboutS
              register={register}
              selectedValue={selectedValue}
              onRadioChange={handleRadioChange}
            />
            <br />
            {selectedValue === 'job' ? (
              <>
                {' '}
                <p>
                  Visit our{' '}
                  <a href="http://www.genesiscareers.jobs/">Careers Page</a> to
                  view and apply for current openings and learn more about
                  careers at Genesis.
                </p>
              </>
            ) : (
              <>
                <InputS
                  label="Name"
                  register={register}
                  placeholder="Your name"
                  required
                  ref={nameFieldRef}
                />
                <InputS
                  label="ContactInfo"
                  register={register}
                  placeholder="Your phone or email"
                  required
                />
                <ContactMessageS
                  placeholder="Do you have any additional details?"
                  label="Message"
                  register={register}
                />
                <br />
                <SubmitInputS type="submit" disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </SubmitInputS>
                <br />
              </>
            )}
          </FormS>
        ) : null}
      </ContainerDivS>
    </>
  )
}

const ContainerDivS = styled.div``
const FormDoneS = styled.div``
const FormErrorS = styled.div``

const FormS = styled.form`
  min-width: 200px;
`
const SubmitInputS = styled.button`
  width: 100%;
  padding: 10px 15px;
  background: #e61d26;
  border: 0 none;
  cursor: pointer;
  -webkit-border-radius: 5px;
  color: white;
  border-radius: 5px;
  font-weight: 600;
`
const InputS = styled(Input)``

const ContactMessageS = styled(ContactMessage)``

const ContactAboutS = styled(ContactAbout)``

export default ContactForm
